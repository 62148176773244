import { createRouter, createWebHistory } from 'vue-router'

// 로그인 페이지
import login from '../views/login.vue'

// client views
import projectList from '../views/client/projectList.vue'; // 프로젝트 리스트
import createProject from '../views/client/createProject.vue'; // 프로젝트 생성
import resultProject from '../views/client/resultProject.vue'; // 프로젝트 결과

// admin views
import languageSetting from '../views/admin/languageSetting.vue'; // 언어관리
import engineSetting from "@/views/admin/engineSetting.vue"; // 번역 엔진 관리자
import fieldSetting from '../views/admin/fieldSetting.vue'; // 분야관리
import promptSetting from '../views/admin/promptSetting.vue'; // 프롬프트 관리
import userSetting from "@/views/admin/userSetting.vue"; // 사용자 관리
import adminPage from "@/views/admin/adminPage.vue"; // 관리자 페이지 허브

const routes = [
  {
    path: '/',
    name: 'Login',
    component: login,
    meta: { title: 'Login | TransCreation Pro' },
  },
  {
    path: '/projects',
    children: [
      {
        path: 'list',
        name: 'ProjectList',
        component: projectList,
        meta: { title: 'Project List | TransCreation Pro' },
      },
      {
        path: 'create',
        name: 'CreateProject',
        component: createProject,
        meta: { title: 'Create Project | TransCreation Pro' },
      },
      {
        path: 'result',
        name: 'ResultProject',
        component: resultProject,
        meta: { title: 'Project Result | TransCreation Pro' },
      },
    ],
  },
  {
    path: '/admin',
    name: 'AdminPage',
    redirect: '/admin/language',
    component: adminPage,
    meta: { title: 'Admin Dashboard | TransCreation Pro' },
    children: [
      {
        path: 'language',
        name: 'LanguageSetting',
        component: languageSetting,
        meta: { title: 'Language Setting | TransCreation Pro' },
      },
      {
        path: 'engine',
        name: 'EngineSetting',
        component: engineSetting,
        meta: { title: 'Engine Setting | TransCreation Pro' },
      },
      {
        path: 'field',
        name: 'FieldSetting',
        component: fieldSetting,
        meta: { title: 'Field Setting | TransCreation Pro' },
      },
      {
        path: 'prompt',
        name: 'PromptSetting',
        component: promptSetting,
        meta: { title: 'Prompt Setting | TransCreation Pro' },
      },
      {
        path: 'user',
        name: 'UserSetting',
        component: userSetting,
        meta: { title: 'User Setting | TransCreation Pro' },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'TransCreation Pro';
  }
  next();
});

export default router;
