<script>
export default {
  name: "i-chevron",
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M17.6651 15.1883C17.3294 15.5549 16.7601 15.5799 16.3935 15.2442L12.0006 11.221L7.60933 15.2441C7.24283 15.5798 6.67352 15.5549 6.33775 15.1884C6.00198 14.8219 6.0269 14.2526 6.3934 13.9169L11.3926 9.33685C11.7366 9.02171 12.2644 9.02166 12.6084 9.33676L17.6092 13.9168C17.9758 14.2525 18.0008 14.8218 17.6651 15.1883Z"
          fill="currentColor"/>
  </svg>
</template>

<style scoped>

</style>
