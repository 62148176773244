<script>
export default {
  name: "i-logout"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2.57143 1.71429C2.3441 1.71429 2.12608 1.80459 1.96534 1.96534C1.80459 2.12608 1.71429 2.3441 1.71429 2.57143V21.4286C1.71429 21.6559 1.80459 21.8739 1.96534 22.0347C2.12608 22.1954 2.3441 22.2857 2.57143 22.2857H14.5714C14.7988 22.2857 15.0168 22.1954 15.1775 22.0347C15.3383 21.8739 15.4286 21.6559 15.4286 21.4286V18C15.4286 17.5266 15.8123 17.1429 16.2857 17.1429C16.7591 17.1429 17.1429 17.5266 17.1429 18V21.4286C17.1429 22.1106 16.8719 22.7646 16.3897 23.2468C15.9075 23.7291 15.2534 24 14.5714 24H2.57143C1.88944 24 1.23539 23.7291 0.753154 23.2468C0.270918 22.7646 0 22.1106 0 21.4286V2.57143C0 1.88944 0.270918 1.23539 0.753154 0.753154C1.23539 0.270918 1.88944 0 2.57143 0H14.5714C15.2534 0 15.9075 0.270918 16.3897 0.753154C16.8719 1.23539 17.1429 1.88944 17.1429 2.57143V6C17.1429 6.47339 16.7591 6.85714 16.2857 6.85714C15.8123 6.85714 15.4286 6.47339 15.4286 6V2.57143C15.4286 2.3441 15.3383 2.12608 15.1775 1.96534C15.0168 1.80459 14.7988 1.71429 14.5714 1.71429H2.57143Z"
          fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M10.2852 11.9997C10.2852 11.5263 10.6689 11.1426 11.1423 11.1426H23.1423C23.6157 11.1426 23.9994 11.5263 23.9994 11.9997C23.9994 12.4731 23.6157 12.8569 23.1423 12.8569H11.1423C10.6689 12.8569 10.2852 12.4731 10.2852 11.9997Z"
          fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M19.1085 7.96492C19.4432 7.63018 19.9859 7.63018 20.3207 7.96492L23.7492 11.3935C24.084 11.7282 24.084 12.2709 23.7492 12.6057L20.3207 16.0342C19.9859 16.369 19.4432 16.369 19.1085 16.0342C18.7737 15.6995 18.7737 15.1568 19.1085 14.8221L21.931 11.9996L19.1085 9.1771C18.7737 8.84237 18.7737 8.29965 19.1085 7.96492Z"
          fill="currentColor"/>
  </svg>
</template>

<style scoped>

</style>
