<script>
export default {
  name: "i-file"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M3.83163 0.0380001C2.97418 0.193352 2.26056 0.768632 1.91432 1.58372C1.80339 1.84479 1.76079 2.01548 1.72947 2.32426C1.6945 2.66922 1.69426 21.3272 1.72925 21.6752C1.84611 22.8377 2.70562 23.7631 3.8546 23.9635C4.03731 23.9953 5.02668 23.9997 12.0003 23.9997C20.7237 23.9997 20.1149 24.01 20.5614 23.8553C20.9171 23.732 21.2268 23.54 21.5011 23.2727C21.9592 22.8263 22.2084 22.3002 22.2733 21.6423C22.2894 21.4798 22.2954 18.8886 22.291 14.0397L22.2843 6.68372L22.2276 6.53972C22.1735 6.40225 22.0296 6.25424 19.049 3.27097C16.2056 0.424904 15.9142 0.140192 15.7817 0.0789681L15.6363 0.0117201L9.82827 0.00778413C4.89538 0.00442413 3.99185 0.00898412 3.83163 0.0380001ZM4.01777 1.76175C3.80369 1.82876 3.61416 2.00293 3.50643 2.23172L3.44427 2.36372V12.0002V21.6367L3.52316 21.7969C3.61042 21.9742 3.75296 22.1162 3.93238 22.2046L4.04427 22.2597H12.0003H19.9563L20.0763 22.1997C20.2398 22.1179 20.414 21.9391 20.4918 21.7734L20.5563 21.6357L20.5625 15.1021L20.5687 8.56842L17.4545 8.56208L14.3403 8.55572L14.2262 8.50306C14.0562 8.42451 13.8663 8.23246 13.7852 8.05705L13.7163 7.90772L13.7097 4.81772L13.703 1.72772L8.90967 1.72933C5.07716 1.7306 4.09652 1.7371 4.01777 1.76175ZM15.4323 4.47572V6.86372H17.8262H20.2202L17.8323 4.47572C16.5189 3.16232 15.4383 2.08772 15.4383 2.08772C15.4383 2.08772 15.4323 3.16232 15.4323 4.47572Z"
          fill="currentColor"/>
  </svg>
</template>

<style scoped>

</style>
