export const ENDPOINTS = {
    // AUTHENTICATION
    LOGIN: 'users/login',
    REFRESH_TOKEN: 'users/refresh-token',

    // PROJECT LIST
    GET_PROJECT_LIST: 'translation-projects',
    SEARCH_PROJECT_LIST: 'tc1-compatible/search-translation-projects',

    //CREATE PROJECT
    DETECT_SOURCE_LANGUAGE_AND_WORD_COUNT: 'tc1-compatible/detect-language',
    CREATE_PROJECT: 'tc1-compatible/generate-translation-project',

    GET_NEW_PROJECT_LOADING: 'tc1-compatible/get-residual-celery-tasks-count?translation_project_id=',

    // PROJECT
    GET_PROJECT_TEXTS: 'tc1-compatible/project-text-sentences/',

    GET_GLOSSARY_DATA: 'translation-projects',
    UPDATE_GLOSSARY_DATA: 'translation-projects/',

    DELETE_PROJECT: 'translation-projects/',
    CREATE_TRANSLATION_ALL: 'tc1-compatible/translation-whole',
    CREATE_TRANSLATION_PARTIAL: 'tc1-compatible/translation-partial',

    // LANGUAGE LIST
    GET_PROJECT_LANGUAGE_LIST_DEAFAULT: 'languages',

    // TRANSLATION MODEL LIST
    GET_TRANSLATION_MODEL_LIST_DEFAULT: 'translation-models',

    // FIELD LIST
    GET_FIELD_LIST_DEAFAULT: 'translation-project-fields',

    // PUBLIC PROMPT LIST
    GET_PUBLIC_PROMPT_LIST: 'public-prompts',

    // DOCUMENT TYPE LIST
    GET_DOCUMENT_LIST: 'document-types',


    // DOWNLOAD
    DOWNLOAD_ORIGINAL_DOCUMENT: 'tc1-compatible/download-original-document?translation_project_id=',
    DOWNLOAD_GLOSSARY_DOCUMENT: 'tc1-compatible/download-glossary-data-list?translation_project_id=',
    DOWNLOAD_TRANSLATION_DOCUMENT: 'tc1-compatible/download-translation-document?translation_project_id=',

    // ADMIN
    // Language
    GET_LANGUAGE_LIST: 'admin/langs/get-language-list',
    CREATE_LANGUAGE: 'admin/langs/create-language',
    DELETE_LANGUAGE: 'admin/langs/',

    // Translation Model
    GET_TRANSLATION_MODEL_LIST: 'admin/translation-models/get-translation-model-list',
    CREATE_TRANSLATION_MODEL: 'admin/translation-models/create-translation-model',
    UPDATE_TRANSLATION_MODEL: 'admin/translation-models/',
    DELETE_TRANSLATION_MODEL: 'admin/translation-models/',

    // Field
    GET_FIELD_LIST: 'admin/fields/get-field-list',
    CREATE_FIELD: 'admin/fields/create-field',
    DELETE_FIELD: 'admin/fields/',

    // Prompt
    GET_PROMPT_LIST: 'admin/prompts/get-prompt-list',
    CREATE_PROMPT: 'admin/prompts/create-prompt',
    UPDATE_PROMPT: 'admin/prompts/',
    DELETE_PROMPT: 'admin/prompts/',

    //User
    GET_USER_LIST: 'admin/users/get-user-list',
    GET_SINGLE_USER_DATA: 'admin/users/',
    CREATE_USER: 'admin/users/create-user',
    DELETE_USER: 'admin/users/'
};
