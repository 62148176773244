<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'

export default {
  setup() {
    const windowSize = ref({
      width: 0,
      height: 0
    })

    const getWindowSize = () => {
      windowSize.value.width = window.innerWidth
      windowSize.value.height = window.innerHeight
    }

    const resizeHandler = () => {
      getWindowSize()
    }

    // 창 크기 변경 이벤트 리스너 등록
    onMounted(() => {
      getWindowSize()
      window.addEventListener('resize', resizeHandler)
    })

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeHandler)
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('refreshToken');
      sessionStorage.removeItem('userId');
    })

    return {
      windowSize
    }
  }
}
</script>

<template>
  <div>
    <common-header :window="windowSize"></common-header>
    <router-view class="content" :class="{ 'mobile': windowSize.width < 769 }" :window="windowSize"></router-view>
  </div>
</template>

<style lang="scss">
@import 'assets/scss/app';
</style>
