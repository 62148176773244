<script setup>
import {computed, onMounted, ref} from 'vue'
import {useAuthStore} from "@/store/auth";
import ISearch from "@/assets/img/icon/i-search.vue";
import IDownload from "@/assets/img/icon/i-download.vue";
import IChevron from "@/assets/img/icon/i-chevron.vue";
import {useRouter} from "vue-router";
import {useStore} from "@/store";

const props = defineProps({
  window: {
    type: Object,
    required: false, // window 객체가 없을 수도 있음
  }
});
const authStore = useAuthStore();
const store = useStore();
const router = useRouter();
const projectList = ref();
const downloadHover = ref(null);

// Pagenation Set
const selectedPage = ref(1);
const totalPage = computed(() => Math.ceil(store.projectListData.count / 10));
const pageNationArr = ref([]);
const searchType = ref('all');
const searchText = ref('');

function setPageNation() {
  let temp = [];
  const firstPage = 10 * (selectedPage.value - 1) + 1;
  const lastPage = 10 * selectedPage.value;

  for (let i = 1; i <= totalPage.value; i++) {
    if (i >= firstPage && i <= lastPage) {
      temp.push(i);
    }

    if (temp.length === 10) {
      break;
    }
  }

  pageNationArr.value = temp;
}

function goProject(projectId) {
  router.push({
    name: 'ResultProject',
    query: {projectId: projectId},
  })
}

async function movePage(page) {
  if(page === -1) { // prev
    if (selectedPage.value - 1 === 0) {
      return;
    }
    selectedPage.value -= 1;
  }

  else if(page === 9999) { // next
    if (selectedPage.value === totalPage.value) {
      return;
    }
    selectedPage.value += 1;
  }

  else {
    selectedPage.value = page;
  }

  projectList.value = await store.getProjectList(selectedPage.value);
}

function matchFieldIndexWithName(index) {
  if(store.fieldList.length === 0) {
    store.getFieldList();
  }

  const temp = store.fieldList.find(fl => fl.id === index);
  return temp ? temp.name : '';
}

function getLanguageById(id) {
  const temp = store.languageListDefault.find(pll => pll.id === id);
  return temp.name;
}

function getTranslationModelById(id) {
  const temp = store.translationModelListDefault.find(tml => tml.id === id);
  return temp.model_name;
}

async function searchProjectByType() {
  try {
    await store.searchProjectList(searchText.value, searchType.value)
  } catch (e) {
    console.error(e);
  }
}

onMounted(async () => {
  if (!authStore.isAuthenticated) {
    await router.push({name: 'Login'});
  }
  if(!!store.languageListDefault) {
    await store.getLanguageListDefault();
  }
  if(!!store.translationModelListDefault) {
    await store.getTranslationModelListDefault();
  }
  if (!!store.projectListData) {
    projectList.value = await store.getProjectList();
  }
  setPageNation();
})
</script>
<template>
  <div id="projectList" v-if="authStore.isAuthenticated">
    <h1 class="title">프로젝트 리스트</h1>

    <div class="project_control_wrapper">
      <div class="search_project">
        <select class="type_select" v-model="searchType">
          <option value="all">제목</option>
          <option value="display_name">프로젝트명</option>
          <option value="tamsecode">TAMSE</option>
          <option value="source_language">출발어</option>
          <option value="target_language">도착어</option>
          <option value="translation_model_name">번역 엔진명</option>
          <option value="project_field">분야명</option>
        </select>

        <i class="vertical"></i>

        <div class="search_input">
          <label for="searchText"></label>
          <input id="searchText" type="text" placeholder="검색어를 입력하세요" name="searchText" v-model="searchText" @keydown.enter="searchProjectByType()"/>
          <i-search class="icon size_16 blue"/>
        </div>
      </div>

      <div>
        <router-link :to="{ name: 'CreateProject' }" class="btn size_20 primary">프로젝트 추가</router-link>
      </div>
    </div>

    <p class="project_count">{{store.projectListData.count}}개의 프로젝트</p>

    <div class="table_wrapper">
      <div class="table_header">
        <div>No.</div>
        <div>TAMSE 분야</div>
        <div>프로젝트</div>
        <div>언어/분량</div>
        <div>번역 엔진</div>
        <div>다운로드</div>
      </div>

      <div v-for="(pl, index) in store.projectListData.result" :key="index" class="table_row">
        <div class="index" @click="goProject(pl.id)">{{ store.modifyIndex(pl.id) }}</div>
        <div class="tamse_type" @click="goProject(pl.id)">
          <i class="icon tamse size_32" :class="store.setTamseClassName(pl.tamse_code)"></i>
          <span>{{matchFieldIndexWithName(pl.project_field_id)}}</span>
        </div>
        <div class="project_name" @click="goProject(pl.id)">
          <span>{{ pl.id }}</span>
          <p>{{ pl.display_name }}</p>
        </div>
        <div class="language" @click="goProject(pl.id)">
          <p>{{`${getLanguageById(pl.source_language_id)} - ${getLanguageById(pl.target_language_id)}`}}</p>
          <span>{{ pl.string_count }}</span>
        </div>
        <div class="engine" @click="goProject(pl.id)">{{ getTranslationModelById(pl.translation_model_id) }}</div>
        <div class="download">
          <i-download class="icon size_20" @mouseenter=" downloadHover = index "/>

          <div class="download_hovered_wrapper" :class="{ 'download_hover' : downloadHover === index }"
               @mouseleave="downloadHover = null">
            <p @click="store.downloadOriginalDocument(pl.id)">원본 파일</p>
            <p @click="store.downloadTranslationDocument(pl.id)">번역문 파일</p>
            <p @click="store.downloadGlossaryDocument(pl.id)">용어집 파일</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="pageNationArr.length > 1" class="page_nation">
      <div class="page_move" @click="movePage(-1)">
        <i-chevron class="icon size_20 left"/>
      </div>

      <p v-for="(pna, index) in pageNationArr" :key="index" class="page_num"
         :class="{ 'selected' : selectedPage === pna }" @click="movePage(index+1)">
        {{ pna }}</p>

      <div class="page_move" @click="movePage(9999)">
        <i-chevron class="icon size_20 right"/>
      </div>
    </div>
  </div>
</template>
