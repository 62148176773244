<script setup>
import {onMounted, ref} from 'vue'
import IDownload from "@/assets/img/icon/i-download.vue";
import IChevron from "@/assets/img/icon/i-chevron.vue";
import {useStore} from "@/store";
import api from "@/api/api";
import {ENDPOINTS} from "@/api/constants";
import IFile from "@/assets/img/icon/i-file.vue";
import IEdit from "@/assets/img/icon/i-edit.vue";
import {useAuthStore} from "@/store/auth";
import {useRouter} from "vue-router";
import IClose from "@/assets/img/icon/i-close.vue";

const props = defineProps({
  window: {
    type: Object,
    required: false, // window 객체가 없을 수도 있음
  }
});
const authStore = useAuthStore();
const store = useStore();
const router = useRouter();

// Variables
const uploadedFile = ref(null);
const uploadedGlossaryFile = ref(null);
const fileInput = ref(null);
const glossaryFileInput = ref(null);
const isDragging = ref(false);
const fileUploadProgress = ref(0);
const detectedFileData = ref({
  name: '',
  size: '',
  data: {}
});
const detectedGlossaryFileData = ref({
  name: '',
  size: '',
  data: {}
});

const formData = ref({
  tamseCode: 'A-1',
  sourceLangId: 0,
  targetLangId: 0,
  publicPromptId: 1,
  translationModelId: 3,
  have_private_prompt: false,
  private_prompt: '',
  projectFieldId: 1,
  displayName: '',
  documentTypeId: 35,
  promptContent: ''
})

const publicPromptList = ref([]);

const flags = ref({
  isFileUploaded: false,
  fileName: false,
  isGlossaryFileUploaded: false,
  glossaryFileName: false,
  creatingProject: false,
})
const usingCustomPrompt = ref('');

const timeoutId = ref(null);

// Functions
function onDragOver() {
  isDragging.value = true;
}

function onDragLeave() {
  isDragging.value = false;
}

function onDrop(event) {
  isDragging.value = false;
  uploadedFile.value = event.dataTransfer.files[0];
  handleFiles(uploadedFile.value);
}

function onGlossaryFileDrop(event) {
  isDragging.value = false;
  uploadedGlossaryFile.value = event.dataTransfer.files[0];
  handleFiles(uploadedGlossaryFile.value);
}

function onClick() {
  fileInput.value.click();
}

function onClickGlossary() {
  glossaryFileInput.value.click()
}

function onFileSelect(event) {
  const maxFileSizeMB = 50;

  if (event.target.files[0].size > maxFileSizeMB * 1024 * 1024) {
    alert(`파일 크기가 ${maxFileSizeMB}MB를 초과합니다. 다른 파일을 선택해주세요.`);
    return;
  }

  uploadedFile.value = event.target.files[0];
  handleFiles(uploadedFile.value);
}

function onGlossaryFileSelect(event) {
  const maxFileSizeMB = 50;

  if (event.target.files[0].size > maxFileSizeMB * 1024 * 1024) {
    alert(`파일 크기가 ${maxFileSizeMB}MB를 초과합니다. 다른 파일을 선택해주세요.`);
    return;
  }

  uploadedGlossaryFile.value = event.target.files[0];
  handleGlossaryFiles(uploadedGlossaryFile.value);
}

function cancelFileUpload() {
  fileInput.value = null;
  uploadedFile.value = null;
  isDragging.value = false;
  fileUploadProgress.value = 0;
  detectedFileData.value.name = '';
  detectedFileData.value.size = '';
  detectedFileData.value.data = {};
  flags.value.fileName = false;
  flags.value.isFileUploaded = false;
}

function cancelGlossaryFileUpload() {
  if(confirm("업로드한 용어집을 취소하시겠습니까?")) {
    detectedGlossaryFileData.value.name = '';
    detectedGlossaryFileData.value.size = '';
    detectedGlossaryFileData.value.data = {};
    flags.value.glossaryFileName = false;
    flags.value.isGlossaryFileUploaded = false;
  }
}

function modifyFileSize(fileSize) { // 파일 크기 출력. KB 단위 넘어가면 MB 리턴
  const fileSizeInKB = (fileSize / 1024).toFixed(2);
  const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2);

  let displaySize;

  if (fileSize < 1024 * 1024) {
    displaySize = `${fileSizeInKB} KB`; // 1MB 미만은 KB 단위로 표시
  } else {
    displaySize = `${fileSizeInMB} MB`; // 1MB 이상은 MB 단위로 표시
  }

  return displaySize;
}

function shortenFilename(fullName) {
  const lastDotIndex = fullName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return fullName;
  }

  const fileName = fullName.slice(0, lastDotIndex);
  const extension = fullName.slice(lastDotIndex + 1);

  const shortenedFileName = fileName.slice(0, 10);
  return `${shortenedFileName}...${extension}`;
}

function checkFormData() {
  let result = false;
  const targetUpload = document.querySelector('#fileUpload');
  const targetLang = document.getElementById('tgtLang');
  const targetCustomPrompt = document.getElementsByClassName('radio_btn');
  const deniedAction = 'denied_action';

  if(!flags.value.isFileUploaded) {
    clearTimeout(timeoutId.value);
    targetUpload.classList.remove(deniedAction);
    targetLang.classList.remove(deniedAction);
    targetCustomPrompt[0].classList.remove(deniedAction);
    targetCustomPrompt[1].classList.remove(deniedAction);

    alert("파일을 업로드하세요.");

    targetUpload.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })

    setTimeout(() => {
      targetUpload.classList.add(deniedAction);
    }, 500)


    timeoutId.value = setTimeout(() => {
      targetUpload.classList.remove(deniedAction);
    }, 3000);

    return result;
  }

  if(formData.value.targetLangId === formData.value.sourceLangId) { // 출발어 === 도착어
    clearTimeout(timeoutId.value);
    targetUpload.classList.remove(deniedAction);
    targetLang.classList.remove(deniedAction);
    targetCustomPrompt[0].classList.remove(deniedAction);
    targetCustomPrompt[1].classList.remove(deniedAction);

    alert("출발어와 다른 도착어를 선택하세요.");

    targetLang.classList.add(deniedAction);
    timeoutId.value = setTimeout(() => {
      targetLang.classList.remove(deniedAction);
    }, 3000);

    return result;
  }

  if(formData.value.targetLangId === 0) { // 도착어 미선택
    clearTimeout(timeoutId.value);
    targetUpload.classList.remove(deniedAction);
    targetLang.classList.remove(deniedAction);
    targetCustomPrompt[0].classList.remove(deniedAction);
    targetCustomPrompt[1].classList.remove(deniedAction);

    alert("도착어를 선택하세요.");

    targetLang.classList.add(deniedAction);
    timeoutId.value = setTimeout(() => {
      targetLang.classList.remove(deniedAction);
    }, 3000);

    return result;
  }

  if(usingCustomPrompt.value === '') { // 커스텀 프롬프트 미선택
    clearTimeout(timeoutId.value);
    targetUpload.classList.remove(deniedAction);
    targetLang.classList.remove(deniedAction);
    targetCustomPrompt[0].classList.remove(deniedAction);
    targetCustomPrompt[1].classList.remove(deniedAction);

    alert("커스텀 프롬프트 사용 여부를 선택하세요.");

    targetCustomPrompt[0].classList.add(deniedAction);
    targetCustomPrompt[1].classList.add(deniedAction);
    timeoutId.value = setTimeout(() => {
      targetCustomPrompt[0].classList.remove(deniedAction);
      targetCustomPrompt[1].classList.remove(deniedAction);
    }, 3000);

    return result;
  }

  result = true;
  return result;
}

function addPromptContents(content) {
  if(formData.value.promptContent.includes(content)) {
    return;
  }

  formData.value.promptContent += content;
}

function setFieldContentById(id) {
  const temp = store.fieldListDefault.find(item => item.id === id);
  return temp.default_prompt;
}

function setDocumentPromptContentById(id) {
  const temp = store.documentTypeDefault.find(item => item.id === id);
  return temp.default_prompt;
}

async function handleCustomPrompt(yn) {
  if (flags.value.isFileUploaded) {
    usingCustomPrompt.value = yn;
  }

  if (usingCustomPrompt.value === 'Y') {
    await getPublicPromptList();
  }
}

async function handleFiles(file) {
  detectedFileData.value.name = file.name;
  detectedFileData.value.size = modifyFileSize(file.size);
  formData.value.displayName = file.name;
  detectedFileData.value.data = await detectSourceLanguageAndWordCount(file);
}

async function handleGlossaryFiles(file) {
  detectedGlossaryFileData.value.name = file.name;
  detectedGlossaryFileData.value.size = modifyFileSize(file.size);
  flags.value.isGlossaryFileUploaded = true;
  flags.value.glossaryFileName = true;
}

async function detectSourceLanguageAndWordCount(fileData) {
  try {
    let file = new FormData();
    file.append('file', fileData);

    const res = await api.post(ENDPOINTS.DETECT_SOURCE_LANGUAGE_AND_WORD_COUNT, file, {
      onUploadProgress: (progressEvent) => {
        fileUploadProgress.value = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      },
    });

    if (res.data) {
      flags.value.isFileUploaded = true;

      // language parameter -> id
      const temp = store.languageListDefault.find(lld => lld.parameter === res.data[1].source_language);
      formData.value.sourceLangId = temp.id;

      return res.data[1];
    }

  } catch (e) {
    await store.handleApiError(e)
  }
}

async function createProject() {
  try {
    if(!checkFormData()) {
      return;
    }

    flags.value.creatingProject = true;

    let rb = new FormData();

    rb.append("tamse_code", formData.value.tamseCode);
    rb.append("project_field_id", formData.value.projectFieldId);
    rb.append("source_language_id", formData.value.sourceLangId);
    rb.append("target_language_id", formData.value.targetLangId);
    rb.append("public_prompt_id", formData.value.publicPromptId);

    if(usingCustomPrompt.value === 'Y') {
      rb.append("have_private_prompt", usingCustomPrompt.value === 'Y');
      rb.append("private_prompt", formData.value.promptContent);
    }

    rb.append("translation_model_id", formData.value.translationModelId);
    rb.append("request_sentence_range", 5);
    rb.append("display_name", formData.value.displayName);
    rb.append("document_type_id", formData.value.documentTypeId);
    rb.append("file", uploadedFile.value);

    if(flags.value.isGlossaryFileUploaded) {
      rb.append("glossary_file", uploadedGlossaryFile.value);
    }


    const res = await api.post(ENDPOINTS.CREATE_PROJECT, rb);
    if(res.data.result) {
      const newProject = await store.getProjectList(1, 1);

      await router.push({
        name: 'ResultProject',
        query: {projectId: newProject.result[0].id},
      })
    }
  } catch (e) {
    console.error("create project", e);
    flags.value.creatingProject = false;
  }
}

async function getPublicPromptList() {
  if (usingCustomPrompt.value === 'N' || usingCustomPrompt.value === '') {
    return;
  }

  try {
    const payload = {
      translationProjectFieldId: formData.value.projectFieldId,
      translationModelId: formData.value.translationModelId,
      tamseCode: formData.value.tamseCode,
    }

    const isSuccess = await store.getPublicPromptListDefault(payload);

    if(isSuccess) {
      publicPromptList.value = store.publicPromptListDefault;
    }
  } catch (e) {
    console.error("get public prompt list error: ", e);
  }
}

onMounted(async () => {
  if (!authStore.isAuthenticated) {
    await router.push({name: 'Login'});
  }
  if (!!store.languageListDefault) {
    await store.getLanguageListDefault();
  }
  if (!!store.translationModelListDefault) {
    await store.getTranslationModelListDefault();
  }
  if (!!store.fieldListDefault) {
    await store.getFieldListDefault();
  }
  if(!!store.publicPromptListDefault) {
    const payload = {
      translationProjectFieldId: formData.value.projectFieldId,
      translationModelId: formData.value.translationModelId,
      tamseCode: formData.value.tamseCode,
    }
    await store.getPublicPromptListDefault(payload);
  }
  if(!!store.documentTypeDefault) {
    await store.getDocumentTypeDefault();
  }
});
</script>
<template>
  <div id="createProject">
    <div class="create_project_wrapper">
      <h3 v-show="fileUploadProgress !== 100" class="title">생성형 AI 솔루션이 필요한 파일을 업로드 해주세요.</h3>
      <div v-show="fileUploadProgress === 100" class="file_uploaded_title_wrapper">
        <div v-if="!flags.fileName" class="file_uploaded_title" style="padding-bottom: 9.2px">
          <span class="title" style="text-align: start">{{ formData.displayName }}</span>
          <div class="edit_btn" @click="flags.fileName = true">
            <i-edit class="icon size_12 grey_2"/>
          </div>
        </div>

        <div v-else class="file_uploaded_title editing_mode">
          <input type="text" v-show="fileUploadProgress === 100" id="file_name_input" class="title" v-model="formData.displayName" placeholder="프로젝트명을 입력하세요."/>
          <span class="edit_confirm_btn" @click="flags.fileName = false">확인</span>
        </div>
      </div>

      <div v-show="fileUploadProgress !== 100" id="fileUpload" @dragover.prevent="onDragOver" @dragleave="onDragLeave" @drop.prevent="onDrop" @click="onClick">
        <input type="file" ref="fileInput" @change="onFileSelect" style="display: none;" accept=".pdf, .docx, .xlsx, .pptx" />
        <div class="upload_contents">
          <img src="@/assets/img/upload_cloud.png" alt="upload cloud"/>

          <p>drag & drop here</p>
          <p>최대 파일 첨부 크기는 50MB 입니다.</p>
          <p>docx, xlsx, pptx, pdf 형식만 지원 가능합니다.</p>

          <button class="btn size_21 white">파일 직접 선택하기</button>
        </div>
      </div>

      <div v-show="fileUploadProgress === 100" class="file_uploaded_container">
        <i-file class="icon size_20 blue"/>
        <span class="file_name">{{ detectedFileData.name }}</span>
        <span class="file_size">{{ detectedFileData.size }}</span>
        <i-close class="icon size_12 grey_3 cancel_file_upload" @click="cancelFileUpload"/>
      </div>

      <h6>설정</h6>
      <div class="setting_wrapper">
        <div class="settings_container">
          <!--출발어-->
          <div class="setting_item">
            <label for="srcLang"><strong>*</strong>출발어</label>
            <div class="select_container">
              <select id="srcLang" v-model="formData.sourceLangId" :disabled="!flags.isFileUploaded">
                <option value="0" disabled>자동인식</option>
                <option v-for="lld in store.languageListDefault" :key="lld" :value="lld.id">{{lld.name}}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--도착어-->
          <div class="setting_item">
            <label for="tgtLang"><strong>*</strong>도착어</label>
            <div class="select_container">
              <select id="tgtLang" v-model="formData.targetLangId" :disabled="!flags.isFileUploaded">
                <option value="0" disabled>선택</option>
                <option v-for="lld in store.languageListDefault" :key="lld" :value="lld.id">{{lld.name}}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--용어집-->
          <div class="setting_item">
            <div class="glo_title">
              <label>용어집</label>
              <div class="btn size_16 grey">
                <a download="../sample_file/sample_glossary.xlsx">샘플</a>
                <i-download class="icon size_16 grey_3"/>
              </div>
            </div>
            <button v-show="!flags.isGlossaryFileUploaded" :disabled="!flags.isFileUploaded" class="btn size_18 blue" id="glossaryFileUpload"
                 @dragover.prevent="onDragOver" @dragleave="onDragLeave" @drop.prevent="onGlossaryFileDrop"
                 @click="onClickGlossary">
              <input type="file" ref="glossaryFileInput" @change="onGlossaryFileSelect" style="display: none;"/>
              + 첨부하기
            </button>
            <button v-show="flags.isGlossaryFileUploaded" class="btn size_18 blue glo_file_name" @click="cancelGlossaryFileUpload">
              {{ shortenFilename(detectedGlossaryFileData.name) }}
              <i-close class="icon size_12 blue" />
            </button>
          </div>

          <!--TAMSE-->
          <div class="setting_item">
            <label for="tamse"><strong>*</strong>TAMSE</label>
            <div class="select_container">
              <select id="tamse" :disabled="!flags.isFileUploaded" v-model="formData.tamseCode" @change="getPublicPromptList">
                <option value="" disabled>선택</option>
                <option value="A-1">A1</option>
                <option value="A-2">A2</option>
                <option value="A-3">A3</option>
                <option value="B-1">B1</option>
                <option value="B-2">B2</option>
                <option value="B-3">B3</option>
                <option value="C-1">C1</option>
                <option value="C-2">C2</option>
                <option value="C-3">C3</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--번역 엔진-->
          <div class="setting_item">
            <label for="engine"><strong>*</strong>번역 엔진</label>
            <div class="select_container">
              <select id="engine" :disabled="!flags.isFileUploaded" v-model="formData.translationModelId" @change="getPublicPromptList">
                <option value="" disabled>선택</option>
                <option v-for="tmld in store.translationModelListDefault" :key="tmld" :value="tmld.id">{{ tmld.model_name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--커스텀 프롬프트-->
          <div class="setting_item">
            <label><strong>*</strong>커스텀 프롬프트</label>
            <div class="select_container">
              <div class="radio_btn" :class="{ 'selected' : usingCustomPrompt === 'Y' }" @click="handleCustomPrompt('Y')">
                <input class="radio_input" type="radio" name="using" value="Y" v-model="usingCustomPrompt" :disabled="!flags.isFileUploaded"/>
                <label for="using">사용</label>
              </div>
              <div class="radio_btn" :class="{ 'selected' : usingCustomPrompt === 'N' }" @click="handleCustomPrompt('N')">
                <input class="radio_input" type="radio" name="using" value="N" v-model="usingCustomPrompt" :disabled="!flags.isFileUploaded"/>
                <label for="using">미사용</label>
              </div>
            </div>
          </div>

          <!--전체 분야-->
          <div v-show=" usingCustomPrompt === 'N' " class="setting_item">
            <label for="allField"><strong>*</strong>전체 분야</label>
            <div class="select_container">
              <select id="allField" v-model="formData.projectFieldId">
                <option value="" disabled>선택</option>
                <option v-for="fld in store.fieldListDefault" :key="fld" :value="fld.id">{{ fld.name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>
        </div>
      </div>

      <h6 v-if=" usingCustomPrompt === 'Y' ">커스텀 프롬프트</h6>
      <div v-if=" usingCustomPrompt === 'Y' " class="custom_prompt">
        <div class="settings_container">

          <!--분야-->
          <div class="setting_item">
            <label for="field"><strong>*</strong>분야</label>
            <div class="select_container">
              <select id="field" v-model="formData.projectFieldId" @change="addPromptContents(setFieldContentById(formData.projectFieldId))">
                <option value="" disabled>선택</option>
                <option v-for="fld in store.fieldListDefault" :key="fld" :value="fld.id">{{ fld.name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--문서 타입-->
          <div class="setting_item">
            <label for="documentType"><strong>*</strong>문서 타입</label>
            <div class="select_container">
              <select id="documentType" v-model="formData.documentTypeId"  @change="addPromptContents(setDocumentPromptContentById(formData.documentTypeId))">
                <option v-for="dtd in store.documentTypeDefault" :key="dtd" :value="dtd.id">{{ dtd.name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--프롬프트-->
          <div class="setting_item">
            <label for="prompt"><strong>*</strong>프롬프트(기업명)</label>
            <div class="select_container">
              <select id="prompt" v-model="formData.publicPromptId">
                <option value="0" disabled>선택</option>
                <option v-for="cpl in publicPromptList" :key="cpl" :value="cpl.id">{{ cpl.name }}</option>
              </select>
              <i-chevron class="icon size_20 down grey_3"/>
            </div>
          </div>

          <!--방식-->
<!--          <div class="setting_item">-->
<!--            <label for="promptMode"><strong>*</strong>방식</label>-->
<!--            <div class="select_container">-->
<!--              <select id="promptMode">-->
<!--                <option value="segment">세그먼트 분리</option>-->
<!--              </select>-->
<!--              <i-chevron class="icon size_20 down grey_3"/>-->
<!--            </div>-->
<!--          </div>-->

          <!--프롬프트 내용-->
          <div class="setting_item prompt_text_area">
            <label for="promptTextArea">프롬프트 내용</label>
            <textarea id="promptTextArea" v-model="formData.promptContent"></textarea>
          </div>
        </div>

      </div>

      <button class="btn size_36 primary editing_btn" :class="{ 'creating': flags.creatingProject }" @click="createProject">
        <span v-if="!flags.creatingProject">에디팅 하기</span>
        <div v-else class="loader">
          <div class="loading" style="width: 16px;"></div>
        </div>
      </button>
    </div>
  </div>
</template>
