<script>
export default {
  name: "i-download"
}
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Download">
      <path id="Icon (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
            d="M12.0013 3C12.5597 3 13.0125 3.36631 13.0125 3.81818V12.7022L16.4233 9.94757C16.8186 9.62834 17.4588 9.62881 17.8534 9.94862C18.2479 10.2684 18.2474 10.7865 17.8521 11.1057L12.7157 15.254C12.3209 15.5729 11.6816 15.5729 11.2868 15.254L6.15041 11.1057C5.75514 10.7865 5.75456 10.2684 6.14912 9.94862C6.54367 9.62881 7.18395 9.62834 7.57922 9.94757L10.99 12.7022V3.81818C10.99 3.36631 11.4428 3 12.0013 3ZM20.9888 14.0949C21.5473 14.0949 22 14.4612 22 14.9131V18.2659C22 18.9776 21.6689 19.6682 21.0641 20.1839C20.4576 20.701 19.6234 21 18.7416 21H5.25843C4.37665 21 3.54241 20.701 2.93591 20.1839C2.33112 19.6682 2 18.9776 2 18.2659L2 14.9131C2 14.4612 2.45275 14.0949 3.01124 14.0949C3.56973 14.0949 4.02247 14.4612 4.02247 14.9131L4.02247 18.2659C4.02247 18.5705 4.16487 18.8545 4.40294 19.0575C4.6393 19.259 4.94822 19.3636 5.25843 19.3636H18.7416C19.0518 19.3636 19.3607 19.259 19.5971 19.0575C19.8351 18.8545 19.9775 18.5705 19.9775 18.2659V14.9131C19.9775 14.4612 20.4303 14.0949 20.9888 14.0949Z"
            fill="currentColor"/>
    </g>
  </svg>

</template>

<style scoped>

</style>
