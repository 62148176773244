import { createApp } from 'vue'
import { createPinia } from "pinia";
import App from './App.vue'
import router from './router'
import commonHeader from './components/commonHeader.vue'
import './assets/css/app.css'
import axios from 'axios'

const pinia = createPinia()
if(process.env.NODE_ENV === 'production') {
    console.log("Welcome to TransCreation Pro!");
}
const app = createApp(App)
app.config.globalProperties.$axios = axios
// axios root url
// axios.defaults.rootPath = 'https://transcreation2.lexcodeapi.com/api/'

app.component('common-header', commonHeader)
    .use(router)
    .use(pinia)
    .mount('#app')
