import axios from "axios";
import { useAuthStore } from "@/store/auth";

const api = axios.create({
    baseURL: process.env.VUE_APP_API_ROOT
});

const setAuthHeader = (config) => {
    const authStore = useAuthStore();
    const token = authStore.userToken.accessToken;

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
};

api.interceptors.request.use(setAuthHeader);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const authStore = useAuthStore();

        if (error.response && error.response.status === 401 && authStore.userToken.refreshToken) {
            try {
                await authStore.refreshToken();
                error.config.headers.Authorization = `Bearer ${authStore.userToken.accessToken}`;
                return api.request(error.config);
            } catch (refreshError) {
                console.log('토큰이 만료되었습니다. 다시 로그인 해주세요.')
                authStore.logout();
            }
        }

        return Promise.reject(error);
    }
);

export default api;
