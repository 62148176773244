<script>
export default {
  name: "i-close"
}
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.99783 18.5005C1.72121 18.2239 1.72121 17.7754 1.99783 17.4988L16.9978 2.49881C17.2745 2.22218 17.7229 2.22218 17.9996 2.49881C18.2762 2.77543 18.2762 3.22392 17.9996 3.50054L2.99956 18.5005C2.72294 18.7772 2.27445 18.7772 1.99783 18.5005Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9996 18.5005C17.7229 18.7772 17.2745 18.7772 16.9978 18.5005L1.99783 3.50054C1.72121 3.22392 1.72121 2.77543 1.99783 2.49881C2.27445 2.22218 2.72294 2.22218 2.99956 2.49881L17.9996 17.4988C18.2762 17.7754 18.2762 18.2239 17.9996 18.5005Z" fill="currentColor"/>
  </svg>
</template>

<style scoped>

</style>
