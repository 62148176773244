<script setup>
import {onMounted, ref} from 'vue'
import {useAuthStore} from "@/store/auth";
import {useRouter} from "vue-router";

const props = defineProps({
  window: {
    type: Object,
    required: false, // window 객체가 없을 수도 있음
  }
});

const authStore = useAuthStore();
const router = useRouter();
const username = ref('');
const password = ref('');

const handleLogin = async () => {
  if(username.value === '') {
    alert('아이디를 입력하세요.');
    return;
  }

  if(password.value === '') {
    alert('비밀번호를 입력하세요.');
    return;
  }

  await authStore.login(username.value, password.value);
}

onMounted(() => {
  if(authStore.isAuthenticated) {
    router.push({name: 'ProjectList'});
  }
})
</script>
<template>
  <div id="loginPage">
    <img class="tc_logo" src="../assets/img/logo.svg" alt="tc logo"/>

    <form @submit.prevent="handleLogin">
      <label for="id" name="id"></label>
      <input v-model="username" id="id" type="text" name="id" placeholder="아이디">
      <label for="pw" name="pw"></label>
      <input v-model="password" id="pw" type="password" name="pw" placeholder="비밀번호">
      <button type="submit" :disabled=" username === '' || password === '' " class="login_btn">로그인</button>
    </form>
  </div>
</template>
