<script>
export default {
  name: "i-synchronize"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_10200_42619)">
      <path
          d="M19.526 18.8501C18.7051 18.8501 17.9338 18.8533 17.1625 18.8501C16.7577 18.8485 16.4792 18.6501 16.3432 18.2679C16.2168 17.9112 16.3256 17.4969 16.6473 17.3146C16.8441 17.2042 17.0985 17.1578 17.329 17.1546C18.4219 17.1386 19.5164 17.1466 20.6094 17.1434C20.8494 17.1434 21.0879 17.1211 21.3279 17.1195C21.912 17.1131 22.304 17.5065 22.3024 18.0887C22.2976 19.4227 22.3024 20.755 22.2832 22.089C22.28 22.3177 22.2352 22.58 22.1168 22.7672C21.9072 23.0983 21.5599 23.2246 21.1727 23.0983C20.7854 22.9735 20.603 22.668 20.587 22.2777C20.5694 21.8251 20.5822 21.3708 20.579 20.9182C20.579 20.7918 20.5646 20.6655 20.5534 20.4895C19.9789 20.9454 19.4764 21.3964 18.9228 21.7755C15.828 23.898 12.4531 24.5314 8.81902 23.5541C6.52751 22.9383 4.59605 21.7179 3.01984 19.9265C1.03398 17.6729 0.0594479 15.0433 0.000240039 12.0603C-0.0125617 11.4014 0.488305 11.0191 1.11559 11.1918C1.49644 11.2974 1.65486 11.5725 1.69807 11.9564C1.79408 12.8009 1.81968 13.6678 2.03731 14.4819C2.96063 17.9192 5.07292 20.3232 8.42056 21.6124C10.9009 22.5672 13.3764 22.4761 15.8216 21.5372C17.1465 21.0285 18.3195 20.2416 19.3132 19.21C19.3692 19.1524 19.4204 19.09 19.4652 19.026C19.486 18.9972 19.4892 18.9572 19.5244 18.8501H19.526Z"
          fill="currentColor"/>
      <path
          d="M4.51422 5.1402C5.28232 5.1402 6.05043 5.13221 6.81853 5.1434C7.3946 5.1514 7.73225 5.53207 7.69064 6.09829C7.66344 6.46296 7.41861 6.74127 7.05056 6.80685C6.89374 6.83404 6.73372 6.84204 6.5737 6.84364C5.28072 6.85643 3.98775 6.86443 2.69638 6.87563C2.1043 6.88043 1.70265 6.52374 1.69624 5.92714C1.68184 4.5676 1.68344 3.20805 1.70105 1.8485C1.70425 1.63417 1.77786 1.39265 1.89627 1.21511C2.1171 0.885623 2.46275 0.767263 2.8468 0.898419C3.22765 1.02958 3.41167 1.33507 3.42127 1.72694C3.43408 2.29795 3.42448 2.86896 3.42448 3.53914C3.86293 3.18086 4.22618 2.86576 4.60703 2.57466C7.64104 0.257033 11.0287 -0.509112 14.7588 0.327409C16.9831 0.826443 18.9017 1.91408 20.4972 3.53114C22.7823 5.84877 23.944 8.66063 24.0016 11.9171C24.0128 12.5825 23.5248 12.9664 22.8975 12.8001C22.515 12.6993 22.3486 12.4274 22.3054 12.0435C22.2158 11.239 22.1902 10.4136 21.9917 9.63471C21.0972 6.11908 18.9609 3.67989 15.5573 2.36673C13.6882 1.64537 11.7808 1.5478 9.83493 1.95727C7.88267 2.36673 6.21365 3.30082 4.78786 4.68436C4.67584 4.79312 4.57823 4.91628 4.47582 5.03304C4.49022 5.06983 4.50302 5.10662 4.51742 5.1434L4.51422 5.1402Z"
          fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_10200_42619">
        <rect width="100%" height="100%" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>

</style>
