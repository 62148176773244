<script setup>
import {computed, onMounted, onUnmounted, ref} from 'vue';
import ISearch from "@/assets/img/icon/i-search.vue";
import {useAuthStore} from "@/store/auth";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import IChevron from "@/assets/img/icon/i-chevron.vue";

const searchText = ref('');
const props = defineProps({
  window: {
    type: Object,
    required: false, // window 객체가 없을 수도 있음
  }
});

// Define emit
const emit = defineEmits(['setModalData']);

const authStore = useAuthStore();
const store = useStore()
const router = useRouter();

// Pagenation Set
const selectedPage = ref(1);
const totalPage = computed(() => Math.ceil(store.promptList.length / 10));
const pageNationArr = ref([]);

function setPageNation() {
  let temp = [];
  const firstPage = 10 * (selectedPage.value - 1) + 1;
  const lastPage = 10 * selectedPage.value;

  for (let i = 1; i <= totalPage.value; i++) {
    if (i >= firstPage && i <= lastPage) {
      temp.push(i);
    }

    if (temp.length === 10) {
      break;
    }
  }

  pageNationArr.value = temp;
}

function movePageByDirection(dir) { // false: prev, true: next
  if (!dir) {
    if (selectedPage.value - 1 === 0) {
      return;
    }

    selectedPage.value -= 1;

  }

  if (dir) {
    if (selectedPage.value === totalPage.value) {
      return;
    }
    selectedPage.value += 1;
  }
}

// emit for setting modal data
function setModalData(action, translationModelId = 0, tamse = '', fieldId = 0, name = '', promptContent = '', promptId = 0) {
  const payload = {
    modalType: 'prompt',
    action: action,
    translationModelId: translationModelId,
    tamse: tamse,
    fieldId: fieldId,
    name: name,
    prompt: promptContent,
    promptId: promptId
  }
  emit('setModalData', payload);
}

function matchFieldIndexWithName(index) {
  const temp = store.fieldList.find(fl => fl.id === index);
  return temp ? temp.name : '';
}

onMounted(async () => {
  if (!authStore.userId) { // JWT 만료시 로그인 페이지 ㄱㄱ
    alert(`You are not authenticated. Redirect to the login page.`);
    await router.push({name: 'Login'});
  }

  if (store.fieldList.length === 0) {
    await store.getFieldList();
  }

  if (store.promptList.length === 0) {
    await store.getPromptList();
  }

  setPageNation();
})

onUnmounted(async () => {
  await store.getPromptList();
})
</script>
<template>
  <div id="promptSetting">
    <div class="top">
      <h3>프롬프트(기업명) 관리</h3>
      <button class="btn size_24 primary" @click="setModalData(false)">프롬프트 추가</button>
    </div>

    <div class="search_container">
      <input type="text" placeholder="검색어를 입력하세요." v-model="searchText"
             @keydown.enter="store.getPromptList(searchText)"/>
      <i-search class="icon size_20 blue"/>
    </div>

    <span class="total_count">{{ store.promptList.length }}개의 프롬프트(기업명)</span>

    <div v-for="(pl, index) in store.promptList" :key="index" class="table_row"
         @click="setModalData(true, pl.translation_model_id, pl.tamse_code, pl.translation_project_field_id, pl.name, pl.prompt, pl.id)">
      <div class="index">{{ store.modifyIndex(index + 1) }}</div>
      <span class="title">{{ pl.name }}</span>
      <span class="parameter">{{ matchFieldIndexWithName(pl.translation_project_field_id) }}</span>
      <div class="tamse_type">
        <i class="icon tamse size_32" :class="`${store.setTamseClassName(pl.tamse_code)}`"></i>
      </div>
    </div>

    <div v-if="pageNationArr.length > 1" class="page_nation" style="margin-top: 80px;">
      <div class="page_move" @click="movePageByDirection(false)">
        <i-chevron class="icon size_20 left"/>
      </div>

      <p v-for="(pageNationArr, index) in pageNationArr" :key="index" class="page_num"
         :class="{ 'selected' : selectedPage === pageNationArr }" @click="selectedPage = pageNationArr">
        {{ pageNationArr }}</p>

      <div class="page_move" @click="movePageByDirection(true)">
        <i-chevron class="icon size_20 right"/>
      </div>
    </div>
  </div>
</template>
