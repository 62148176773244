<script>
export default {
  name: "i-edit"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_10064_4476)">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M17.9276 0.0343024C17.7069 0.0737584 17.423 0.167862 17.2245 0.267294C16.8548 0.452502 16.7805 0.519846 15.1076 2.18465C14.21 3.07791 10.9808 6.29036 7.93157 9.32343C4.88237 12.3565 2.34965 14.8935 2.30331 14.9612C2.22709 15.0726 2.11371 15.46 1.11531 19.0203C0.0539591 22.8051 0.0115751 22.9641 0.0117191 23.1603C0.0118391 23.3444 0.0198311 23.3803 0.0938231 23.528C0.186319 23.7127 0.304087 23.8261 0.502687 23.9216C0.613399 23.9749 0.669607 23.9858 0.839575 23.9869C1.03736 23.9883 1.16367 23.9546 4.97957 22.8846C8.53981 21.8862 8.92733 21.7728 9.03869 21.6966C9.1064 21.6502 11.6326 19.1283 14.6525 16.0923C17.6723 13.0563 20.8795 9.83252 21.7794 8.92832C23.5041 7.19554 23.571 7.12078 23.7575 6.71914C23.9408 6.3242 24.0255 5.81187 23.9776 5.38669C23.9451 5.0977 23.8623 4.78731 23.7575 4.56149C23.5721 4.16225 23.4999 4.08147 21.8174 2.38981C20.9386 1.50622 20.145 0.716238 20.0538 0.634278C19.7605 0.370734 19.3776 0.170694 18.9716 0.0689584C18.7228 0.00663041 18.1825 -0.0112976 17.9276 0.0343024ZM18.0929 1.76223C18.0453 1.78052 17.9642 1.82201 17.9129 1.85446C17.8615 1.88691 14.6605 5.05719 10.7995 8.89957L3.77957 15.8857L2.9438 18.8773C2.48413 20.5226 2.11323 21.874 2.11957 21.8803C2.12593 21.8867 3.47732 21.5158 5.12266 21.0561L8.11421 20.2203L15.1127 13.1883C21.6411 6.62854 22.1161 6.14583 22.1853 6.00032C22.2503 5.86388 22.2596 5.81873 22.2596 5.64032C22.2596 5.25937 22.3362 5.35261 20.5132 3.51502C18.6895 1.67672 18.7738 1.74757 18.3956 1.73573C18.254 1.73129 18.1497 1.74041 18.0929 1.76223Z"
            fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_10064_4476">
        <rect width="100%" height="100%" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>

</style>
