<script setup>
import IChevron from "@/assets/img/icon/i-chevron.vue";
import ITrash from "@/assets/img/icon/i-trash.vue";
import IDownload from "@/assets/img/icon/i-download.vue";
import ISetting from "@/assets/img/icon/i-setting.vue";
import ICopy from "@/assets/img/icon/i-copy.vue";
import ISynchronize from "@/assets/img/icon/i-synchronize.vue";
import { nextTick, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";
import api from "@/api/api";
import { ENDPOINTS } from "@/api/constants";
import IClose from "@/assets/img/icon/i-close.vue";
import IVariant from "@/assets/img/icon/i-variant.vue";

const props = defineProps({
  window: {
    type: Object,
    required: false
  }
})

const authStore = useAuthStore();
const store = useStore();
const route = useRoute();
const router = useRouter();

// Variables
const projectBasicInfo = ref({});
const refinedProjectData = ref({
  sourceLang: '',
  targetLang: '',
  field: '',
  translationModel: ''
})

const progressNum = ref(0);

const sentences = ref([]); // API 데이터를 저장
const tree = ref({});
let copiedTree = ref({});
const compareTree = ref({});

const projectText = ref([]);

const compareEngineId = ref(0);

const timeoutId = ref(null);

const glossaryData = ref([]);
const copiedGlossaryData = ref([]);

const projectId = route.query.projectId;

const textareaRefs = ref([]);

const flags = ref({
  loadingTexts: false,
  loadingGlossary: false,
  deleteProject: false,
  downloadHover: false,
  compareEngineOnOff: false,
  loadingCompareEngine: false,
  translationPartial: null,
  loadingTranslationPartial: false,
  tooltipHover: false,
  isGloFocused: null,
  glo: false,
  modalOnOff: false,
})

const formData = ref({
  tamseCode: 'A-1',
  sourceLangId: 0,
  targetLangId: 0,
  publicPromptId: 1,
  translationModelId: 3,
  have_private_prompt: false,
  private_prompt: '',
  projectFieldId: 1,
  displayName: '',
  documentType: '',
  promptMode: 'segment',
  promptName: '',
  promptContents: ''
})

// Functions
function autoResize(subId) {
  nextTick(() => {
    const textarea = textareaRefs.value[subId]; // subId로 정확한 textarea를 참조
    if (textarea) {
      textarea.style.height = "auto"; // 기존 높이 리셋
      textarea.style.height = `${2 * textarea.scrollHeight}px`; // scrollHeight에 맞게 높이 설정
    }
  });
}

function findLanguageName(id) {
  const temp = store.languageListDefault.find(item => item.id === id);
  return temp.name;
}

function findFieldName(id) {
  const temp = store.fieldListDefault.find(item => item.id === id);
  return temp.name;
}

function findTranslationModelName(id) {
  const temp = store.translationModelListDefault.find(item => item.id === id);
  return temp.model_name;

}

///////

const buildTree = () => {
  const treeData = {};

  sentences.value.forEach((item) => {
    const mainId = item.position_main_id;
    const subId = item.position_sub_id;

    if (!treeData[mainId]) {
      treeData[mainId] = {};
    }

    if (!treeData[mainId][subId]) {
      treeData[mainId][subId] = {
        sentences: [],
        concatenatedOriginalText: "",
        concatenatedTranslatedText: "",
        concatenatedTranslatedTextKorean: "",
        concatenatedLatestText: "",

        isHovered: false, // hover 상태 관리
        isTooltipVisible: false, // tooltip 상태 관리
        loading: false, // 로딩 상태 관리
        isNotSaved: false,
      };
    }

    treeData[mainId][subId].sentences.push(item);
  });

  Object.keys(treeData).forEach((mainId) => {
    Object.keys(treeData[mainId]).forEach((subId) => {
      const subNode = treeData[mainId][subId];
      subNode.sentences.sort((a, b) => a.sentence_id - b.sentence_id);

      // 원본 텍스트 합치기 (중복 제거)
      subNode.concatenatedOriginalText = [...new Set(subNode.sentences
        .map((sentence) => sentence.original_text))].join("");

      // 번역된 텍스트 합치기 (중복 제거)
      subNode.concatenatedTranslatedText = [...new Set(subNode.sentences
        .map((sentence) => sentence.translated_text))].join("");

      // 한국어 번역된 텍스트 합치기 (중복 제거)
      subNode.concatenatedTranslatedTextKorean = [...new Set(subNode.sentences
        .map((sentence) => sentence.translated_text_korean))].join("");

      // 한국어 번역된 텍스트 합치기 (중복 제거)
      subNode.concatenatedLatestText = [...new Set(subNode.sentences
        .map((sentence) => sentence.latest_text))].join("");

      if (subNode.concatenatedLatestText === '') {
        subNode.concatenatedLatestText = subNode.concatenatedTranslatedText;
      }
    });
  });
  tree.value = {};
  tree.value = treeData;
  copiedTree.value = {};
  copiedTree.value = structuredClone(treeData);
};

const compareBuildTree = () => {
  const treeData = {};

  sentences.value.forEach((item) => {
    const mainId = item.position_main_id;
    const subId = item.position_sub_id;

    if (!treeData[mainId]) {
      treeData[mainId] = {};
    }

    if (!treeData[mainId][subId]) {
      treeData[mainId][subId] = {
        sentences: [],
        concatenatedOriginalText: "",
        concatenatedTranslatedText: "",
        concatenatedTranslatedTextKorean: "",
        concatenatedLatestText: "",

        isHovered: false, // hover 상태 관리
        isTooltipVisible: false, // tooltip 상태 관리
        loading: false, // 로딩 상태 관리
        isNotSaved: false,
      };
    }

    treeData[mainId][subId].sentences.push(item);
  });

  Object.keys(treeData).forEach((mainId) => {
    Object.keys(treeData[mainId]).forEach((subId) => {
      const subNode = treeData[mainId][subId];
      subNode.sentences.sort((a, b) => a.sentence_id - b.sentence_id);

      // 원본 텍스트 합치기 (중복 제거)
      subNode.concatenatedOriginalText = [...new Set(subNode.sentences
        .map((sentence) => sentence.original_text))].join("");

      // 번역된 텍스트 합치기 (중복 제거)
      subNode.concatenatedTranslatedText = [...new Set(subNode.sentences
        .map((sentence) => sentence.translated_text))].join("");

      // 한국어 번역된 텍스트 합치기 (중복 제거)
      subNode.concatenatedTranslatedTextKorean = [...new Set(subNode.sentences
        .map((sentence) => sentence.translated_text_korean))].join("");

      // 한국어 번역된 텍스트 합치기 (중복 제거)
      subNode.concatenatedLatestText = [...new Set(subNode.sentences
        .map((sentence) => sentence.latest_text !== null ? sentence.latest_text : sentence.translated_text))].join("");
    });
  });

  compareTree.value = {};
  compareTree.value = treeData;
};

const fetchSentences = async (type) => {
  try {
    const result = await store.getProjectTexts(projectId, type);
    sentences.value = result;
    if (type === 0) {
      buildTree();
    }

    if (type === 1) {
      compareBuildTree();
    }

  } catch (e) {
    console.log(e);
  }
}

async function handleKeyDown(event, subNode) { // Handle Keydown event on textarea. mainNode: clonedProjectText
  try {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // 기본 줄바꿈 동작 방지

      const updatedText = event.target.value; // 사용자가 입력한 값

      const isSuccess = await updateSentence(subNode, updatedText);

      if (isSuccess) {
        projectText.value = [];
        projectText.value = await store.getProjectTexts(projectId);
        event.target.blur();
      }
    } else if (event.key === "Enter" && event.shiftKey) { // shift + enter 줄바꿈
    }
  } catch (e) {
    console.error("handleKeyDown error: ", e);
  }
}

async function handleGloKeyDown(event, gd) { // Handle Keydown event on textarea. mainNode: cloned ProjectText
  try {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // 기본 줄바꿈 동작 방지

      flags.value.glo = true;

      const rb = {
        translated_term_latest: gd.translated_term_latest
      }

      const res = await api.patch(`translation-projects/${gd.translation_project_id}/glossary-data/${gd.id}`, rb)

      if (res.data.result) {
        glossaryData.value = await store.getGlossaryData(projectId);
        copiedGlossaryData.value = structuredClone(glossaryData.value);

        for (let i = 0; i < glossaryData.value.length; i++) {
          if (glossaryData.value[i].translated_term_latest === null) {
            glossaryData.value[i].translated_term_latest = glossaryData.value[i].translated_term;
          }
        }
      }

      event.target.blur();
    } else if (event.key === "Enter" && event.shiftKey) { // shift + enter 줄바꿈

    }
  } catch (e) {
    console.error("handleKeyDown error: ", e);
  }
}

function checkNotSavedStatus(mainId, subId, subNode) {
  subNode.isNotSaved =
    subNode.concatenatedLatestText !== copiedTree.value[mainId][subId].concatenatedLatestText;
}

// async function loadProjectText() {
//   try {
//     const result = await store.getProjectTexts(projectId);
//
//     if (result.length === 0) {
//       timeoutId.value = setTimeout(async () => {
//         await loadProjectText();
//       }, 5000);
//     } else {
//       clearTimeout(timeoutId.value);
//       flags.value.loadingTexts = true;
//       return true;
//     }
//   } catch (e) {
//     console.error('loadProjectText error', e);
//   }
// }

async function initProject() {
  try {
    await fetchSentences(0);

    if (!!store.languageListDefault) {// 언어 id -> 이름 매칭
      const isSuccess = await store.getLanguageListDefault();
      if (isSuccess) {
        refinedProjectData.value.sourceLang = findLanguageName(projectBasicInfo.value.source_language_id);
        refinedProjectData.value.targetLang = findLanguageName(projectBasicInfo.value.target_language_id);
      }
    }

    if (!!store.translationModelListDefault) { // 번역 모델 id -> 이름 매칭
      const isSuccess = await store.getTranslationModelListDefault();
      if (isSuccess) {
        refinedProjectData.value.translationModel = findTranslationModelName(projectBasicInfo.value.translation_model_id);
      }
    }

    if (!!store.fieldListDefault) { // 분야 id -> 분야명 매칭
      const isSuccess = await store.getFieldListDefault();
      if (isSuccess) {
        refinedProjectData.value.field = findFieldName(projectBasicInfo.value.project_field_id);
      }
    }

    glossaryData.value = await store.getGlossaryData(projectId);
    copiedGlossaryData.value = structuredClone(glossaryData.value);

    for (let i = 0; i < glossaryData.value.length; i++) {
      if (glossaryData.value[i].translated_term_latest === null) {
        glossaryData.value[i].translated_term_latest = glossaryData.value[i].translated_term;
      }
    }

    formData.value.translationModelId = projectBasicInfo.value.translation_model_id;
    formData.value.projectFieldId = projectBasicInfo.value.project_field_id;
    formData.value.publicPromptId = projectBasicInfo.value.translation_model_id;

    if (!!store.publicPromptListDefault) {
      console.log("projectBasicInfo.value: ", projectBasicInfo.value);
      const payload = {
        translationProjectFieldId: formData.value.projectFieldId,
        translationModelId: formData.value.translationModelId,
        tamseCode: formData.value.tamseCode,
      }
      console.log("payload: ", payload)
      await store.getPublicPromptListDefault(payload);
    }
  } catch (e) {
    console.error("initProject", e);
  }
}

async function getLoadingProgress() {
  try {
    const res = await api.get(ENDPOINTS.GET_NEW_PROJECT_LOADING + projectId);

    console.log(`${res.data.percentage}%`);
    progressNum.value = res.data.percentage;
    if (res.data.percentage < 100) {
      timeoutId.value = setTimeout(async () => {
        await getLoadingProgress();
      }, 3000);
    }

    if (res.data.percentage === 100) {
      projectBasicInfo.value = await store.getProjectInfo(projectId);
      clearTimeout(timeoutId.value);
      await initProject();
      return true;
    }
  } catch (e) {
    console.error("getLoadingProgress error:", e);
    return false;
  }
}

async function deleteProject(yn) { // false: cancel, true: delete
  try {
    if (!yn) {
      const target = document.querySelector('.confirm_container');
      target.classList.add('slide_up');

      setTimeout(() => {
        target.classList.remove('slide_up');
        flags.value.deleteProject = yn;
      }, 300)
      return
    }

    const res = await store.deleteProject(projectId);

    if (res.data.result) {
      alert('프로젝트가 삭제 되었습니다.');
      await router.push({ name: 'ProjectList' });
    }
  } catch (e) {
    console.error("deleteProject", yn);
  }
}

async function createTranslationPartial(subNode) {
  try {
    subNode.loading = true;

    const rb = {
      translation_project_id: projectId,
      translation_column_id: subNode.sentences[0].translation_column_id,
      sentence_id: subNode.sentences[0].sentence_id,
      translation_model_id: projectBasicInfo.value.translation_model_id
    }
    const res = await api.post(ENDPOINTS.CREATE_TRANSLATION_PARTIAL, rb);

    if (res.data.result) {
      const isSuccess = await getSentenceForPartialBtn(subNode);

      if (isSuccess) {
        subNode.loading = false;
      }
    }
  } catch (e) {
    console.error("createTranslationPartial error: ", e);
  }
}

async function putSentenceInTranslatedText(subNode) {

}

async function createTranslationAll(type, fromModal = false) { // type: 0 = 번역문, 1 = 비교 번역문
  try {
    flags.value.loadingCompareEngine = true;

    let rb = {};
    if(!fromModal) {
      rb = {
        translation_project_id: projectId,
        translation_column_id: type,
        translation_model_id: projectBasicInfo.value.translation_model_id
      }
    } else {
      rb = {
        translation_project_id: projectId,
        translation_column_id: type,
        translation_model_id: formData.value.translationModelId
      }
    }

    const res = await api.post(ENDPOINTS.CREATE_TRANSLATION_ALL, rb);

    if (res.data.result) {
      if (type === 0) {
        tree.value = {};
      }

      if (type === 1) {
        compareTree.value = {};
      }

      if (compareEngineId.value !== 0) {
        projectBasicInfo.value.translation_model_comparing_id = compareEngineId.value;
      }

      await fetchSentences(type);
      projectBasicInfo.value = await store.getProjectInfo(projectId);
      flags.value.loadingCompareEngine = false;
    }
  } catch (e) {
    console.error("createTranslationWhole", e);
  }
}

async function getSentence(subNode) { // 한 문장 가져오기
  try {
    const endpoint = `tc1-compatible/project-text-sentences/${projectId}/${subNode.sentences[0].sentence_id}`;

    const res = await api.get(endpoint);

    if (res.data) {
      await fetchSentences(0);
      return true;
    }
  } catch (e) {
    console.error("getSentence", e);
  }
}

async function getSentenceForPartialBtn(subNode) { // 한 문장 가져오기
  try {
    const endpoint = `tc1-compatible/project-text-sentences/${projectId}/${subNode.sentences[0].sentence_id}?translation_column_id=0`;

    const res = await api.get(endpoint);

    if (res.data) {
      await fetchSentences(0);

      return true;
    }
  } catch (e) {
    console.error("getSentenceForPartialBtn", e);
  }
}

async function updateSentence(subNode, updatedText) { // 한 문장 수정
  try {
    const rb = {
      translation_column_id: subNode.sentences[0].translation_column_id,
      latest_text: updatedText,
    }

    const endpoint = `tc1-compatible/project-text-sentences/${projectId}/${subNode.sentences[0].sentence_id}`;
    const res = await api.patch(endpoint, rb);

    if (res.data.result) {
      const isSuccess = await getSentence(subNode);

      if (isSuccess) {
        console.log("updated")
        return true;
      }
    }
  } catch (e) {
    console.error("updateSentence", e);
  }
}

async function updateGlossaryData(gloData) {
  try {
    const setEndpoint = `${ENDPOINTS.UPDATE_GLOSSARY_DATA}${projectId}/glossary-data/${glossary_data_id}`
  } catch (e) {
    console.error("updateGlossaryData", e);
  }
}

async function reTranslation() {
  try {
    flags.value.modalOnOff = false;
    flags.value.loadingCompareEngine = false;
    flags.compareEngineOnOff = false;

    tree.value = {};
    compareTree.value = {};

    await createTranslationAll(0);

    if (Object.keys(compareTree.value).length === 0) {
      await createTranslationAll(1);
    }

    formData.value.promptContents = '';
  } catch (e) {
    console.error("reTranslation", e);
  }
}

const handleMouseEnter = (subNode) => {
  subNode.isHovered = true;
  subNode.isTooltipVisible = true;
};

const handleMouseLeave = (subNode) => {
  subNode.isHovered = false;
  subNode.isTooltipVisible = false;
};

const setInitialHeights = async () => {
  await nextTick(); // DOM 렌더링 완료 후 실행
  Object.keys(tree.value).forEach((mainId) => {
    Object.keys(tree.value[mainId]).forEach((subId) => {
      autoResize(subId); // 각 textarea 높이 조정
    });
  });
};

async function copyToClipboard(text) {
  try {
    if (!text) return;
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error('복사 실패:', err);
  }
}

onMounted(async () => {
  if (!authStore.isAuthenticated) {
    await router.push({ name: 'Login' });
    return
  }
  await getLoadingProgress();

  console.log(glossaryData.value)
  setTimeout(() => {
    setInitialHeights();
  }, 2000)
})


</script>
<template>
  <div id="projectResult">
    <div v-if="flags.deleteProject" class="modal_wrapper">
      <div class="confirm_container">
        <p>프로젝트를 삭제 할까요?</p>
        <div class="btn_wrapper">
          <button class="btn size_28 grey" @click="deleteProject(false)">취소</button>
          <button class="btn size_28 red" @click="deleteProject(true)">삭제하기</button>
        </div>
      </div>
    </div>
    <div class="sub_header">
      <div class="left" @click="router.push({ name: 'ProjectList' })">
        <i-chevron class="icon size_24 left primary" />
        <h1 class="project_name">{{ projectBasicInfo.display_name }}</h1>
      </div>
      <div class="right">
        <i-trash class="icon size_16 black_1" @click="flags.deleteProject = !flags.deleteProject" />
        <div class="download_btn">
          <i-download class="icon size_16 black_1" @mouseenter="flags.downloadHover = true;" />
          <div class="download_hovered_wrapper" :class="{ 'download_hover': flags.downloadHover }"
            @mouseleave="flags.downloadHover = false">
            <p @click="store.downloadOriginalDocument(projectId, projectBasicInfo.display_name)">원본 파일</p>
            <p @click="store.downloadTranslationDocument(projectId, projectBasicInfo.display_name)">번역문 파일</p>
            <p @click="store.downloadGlossaryDocument(projectId, projectBasicInfo.display_name)">용어집 파일</p>
          </div>
        </div>
        <i-setting class="icon size_16 black_1" @click="flags.modalOnOff = true" />
      </div>
    </div>

    <div class="contents_wrapper">
      <div class="main_contents_container">
        <div class="control_panel">
          <div class="language_info">
            <span class="src_language">{{ refinedProjectData.sourceLang }}</span>
            <i-chevron class="icon size_20 right" />
            <span class="tgt_language">{{ refinedProjectData.targetLang }}</span>
          </div>

          <span class="tamse_zone">
            < {{ projectBasicInfo.tamse_code }} 존>
          </span>&nbsp;
          <span class="field_name">
            < {{ refinedProjectData.field }}> 분야
          </span>

          <div class="engine_comparison">
            <span>엔진 비교</span>
            <div class="toggle" :class="{ 'on': flags.compareEngineOnOff }">
              <div class="circle" @click="flags.compareEngineOnOff = !flags.compareEngineOnOff"></div>
            </div>
          </div>
        </div>

        <p class="total_count">{{ projectText.length }}개의 문장</p>

        <div class="main_contents">
          <div class="main_row head">
            <div class="index">No.</div>
            <div class="original">원문</div>
            <div class="translation" :style="{ borderRadius: !flags.compareEngineOnOff ? '0 12px 0 0' : ''}">
              <span class="engine_name">{{ refinedProjectData.translationModel }}</span>
              <span>번역문</span>
              <div class="icon_wrapper">
                <i-synchronize class="icon size_12 primary_dark" @click="createTranslationAll(0)" />
              </div>
            </div>
            <div v-if="flags.compareEngineOnOff" class="comparison_engine">
              <div class="select_container">
                <select v-model="compareEngineId" @change="createTranslationAll(1)" class="comparison_engine_select">
                  <option v-for="tmld in store.translationModelListDefault" :key="tmld" :value="tmld.id">
                    {{ tmld.model_name }}
                  </option>
                </select>
                <i-chevron class="icon size_20 down grey_3" />
              </div>
              <span v-show="!flags.loadingCompareEngine">비교 번역문</span>
              <div v-show="flags.loadingCompareEngine" class="loading" style="width: 10px; height: 10px; padding: 3px;">
              </div>
              <!--              <i-download class="icon download size_12 white"/>-->
            </div>
          </div>

          <div v-if="tree && Object.keys(tree).length === 0" class="loading_row">
            <p>{{ progressNum }}%</p>
            <div class="loading"></div>
          </div>

          <div v-else v-for="(mainNode, mainId, i) in tree" :key="mainId">
            <div v-for="(subNode, subId) in mainNode" :key="subId" class="main_row">
              <span class="index">{{ store.modifyIndex(i + 1) }}</span>
              <p class="original">{{ subNode.concatenatedOriginalText }}</p>
              <div class="translation" @mouseenter="subNode.isHovered = subId" @mouseleave="subNode.isHovered = null">
                <span v-cloak class="badge" :class="{
                  edited: subNode.sentences.some(sentence => sentence.is_edited) && subNode.concatenatedLatestText !== subNode.concatenatedTranslatedText,
                  not_saved: subNode.concatenatedLatestText !== copiedTree[mainId][subId].concatenatedLatestText,
                }"></span>

                <div v-show="subNode.isHovered" class="translate_partial_icon"
                  @click="createTranslationPartial(subNode)" @mouseenter="handleMouseEnter(subNode)"
                  @mouseleave="handleMouseLeave(subNode)">
                  <i-synchronize v-if="!subNode.loading" class="icon size_16 grey_1" />
                  <div v-else class="loading" style="width: 10px; height: 10px; padding: 3px;"></div>
                  <p v-show="subNode.isTooltipVisible" class="tooltip">다시 번역하기</p>
                </div>
                <textarea ref="textareaRefs" @input="autoResize(subId)" @keydown="handleKeyDown($event, subNode)"
                  @blur="checkNotSavedStatus(mainId, subId, subNode)" class="translated_text"
                  :class="{ not_saved: subNode.isNotSaved }" v-model="subNode.concatenatedLatestText"></textarea>
                <hr />
                <p class="back_translated_text">
                  {{ subNode.concatenatedTranslatedTextKorean }}
                </p>
              </div>

              <div v-if="flags.compareEngineOnOff" class="comparison_engine">
                <p class="translated_text">
                  {{ compareTree[mainId]?.[subId]?.concatenatedLatestText || '-' }}
                </p>
                <hr />
                <p class="back_translated_text">
                  {{ compareTree[mainId]?.[subId]?.concatenatedTranslatedTextKorean || '-' }}
                </p>
              </div>
            </div>
          </div>

          <div v-if="flags.compareEngineOnOff && (compareTree && Object.keys(compareTree).length === 0)"
            class="no_data">
            <p>비교 번역할 엔진을 선택하세요</p>

            <div class="select_container">
              <select v-model="compareEngineId" class="comparison_engine_select">
                <option value="0" disabled>엔진명</option>
                <option v-for="tmld in store.translationModelListDefault" :key="tmld" :value="tmld.id">
                  {{ tmld.model_name }}
                </option>
              </select>
              <i-chevron class="icon size_20 down grey_3" />
            </div>

            <button class="btn size_28 primary" :disabled="compareEngineId === 0" @click="createTranslationAll(1)">
              <span v-show="!flags.loadingCompareEngine">결과 보기</span>
              <div v-show="flags.loadingCompareEngine" class="loading" style="width: 16px; height: 16px;"></div>
            </button>
          </div>
        </div>
      </div>

      <!--용어집-->
      <div class="glo_wrapper">
        <div class="title_container">
          <h4>용어집</h4>
          <p>번역문수정이 필요한 경우, 용어집을 먼저 수정 해주세요.</p>
        </div>

        <div class="table">
          <div class="th">
            <p>원문 용어</p>
            <p>번역된 용어</p>
          </div>
          <div v-for="(gd, index) in glossaryData" :key="index" class="tbody">
            <p class="src_glo td">{{ gd.original_term }}</p>
            <div class="tgt_glo td">
              <span class="badge" :class="{
                edited: gd.translated_term_latest !== gd.translated_term && gd.translated_term_latest !== copiedGlossaryData[index].translated_term_latest,
                not_saved: gd.translated_term_latest !== copiedGlossaryData[index].translated_term_latest && copiedGlossaryData[index].translated_term_latest !== null,
              }"></span>
              <textarea v-model="gd.translated_term_latest" @keydown="handleGloKeyDown($event, gd)"
                @focusin="flags.isGloFocused = index" @focusout="flags.isGloFocused = null"></textarea>
              <span v-show="flags.isGloFocused === index" class="focus_msg">엔터를 눌러 저장하세요</span>
              <div v-if="gd.reference !== null" class="glo_recommend" @click="copyToClipboard(gd.reference)">
                <span>{{ gd.reference ? gd.reference : null }}</span>
                <i-copy class="icon size_12" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    Modal-->
    <div v-show="flags.modalOnOff" class="modal_wrapper">
      <div class="modal_container">
        <div class="modal_header">
          <h3>커스텀 프롬프트 설정</h3>
          <i-close class="icon size_20 grey_2 close-button" @click="flags.modalOnOff = false" />
        </div>

        <form class="form_group">
          <label for="engine">번역 엔진</label>
          <select id="engine" v-model="formData.translationModelId">
            <option value="" disabled>선택</option>
            <option v-for="tmld in store.translationModelListDefault" :key="tmld" :value="tmld.id">{{tmld.model_name }}</option>
          </select>

          <!--분야-->
          <label for="field">분야 <span>{{ formData.projectFieldId }}</span></label>
          <select id="field" v-model="projectBasicInfo.project_field_id">
            <option value="" disabled>선택</option>
            <option v-for="fld in store.fieldListDefault" :key="fld" :value="fld.id">{{ fld.name }}</option>
          </select>

          <label for="promptName">프롬프트(기업) <span>{{ formData.promptName }}</span></label>
          <select v-model="formData.promptName">
            <option value="" disabled>프롬프트명을 선택하세요</option>
            <option v-for="pl in store.publicPromptListDefault" :key="pl" :value="pl.name">{{ pl.name }}</option>
          </select>

          <label for="promptMode">방식 <span>{{ formData.promptMode === 'segment' ? '세그먼트 분리' : '세그먼트 병합 방식' }}</span></label>
          <select id="promptMode" v-model="formData.promptMode">
            <option value="segment">세그먼트 분리</option>
            <option value="merge">세그먼트 병합 방식</option>
          </select>

          <label for="promptContents">프롬프트 내용</label>
          <textarea v-model="formData.promptContents" placeholder="프롬프트 내용을 입력하세요"></textarea>
        </form>

        <div class="warning_container">
          <img src="@/assets/img/icon/warning.png" alt="warning" />
          <p>설정 변경 후 재번역 시, 번역문에 적용된 모든 수정 내용이 초기화됩니다.</p>
        </div>

        <button class="btn size_28 primary" @click="reTranslation">반영하기</button>
      </div>
    </div>
  </div>
</template>
