import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { ENDPOINTS } from "@/api/constants";
import api from "@/api/api";
import { useStore } from "@/store/index";

export const useAuthStore = defineStore("auth", () => {
    const store = useStore();
    const router = useRouter();
    const userToken = ref({
        accessToken: sessionStorage.getItem('accessToken') || '',
        refreshToken: sessionStorage.getItem('refreshToken') || '',
    });
    const userId = ref(Number(sessionStorage.getItem("userId")) || 0);
    const isAuthenticated = ref(!!(sessionStorage.getItem('accessToken') && sessionStorage.getItem('refreshToken')));

    async function login(username, password) {
        if (store.throttle) {
            alert("로그인 중입니다. 잠시만 기다려주세요.")
            return;
        }

        store.setThrottling(300000);

        try {
            const rb = {
                bound_type: 'INBOUND',
                name: username,
                password: password
            }

            const res = await api.post(ENDPOINTS.LOGIN, rb);

            userToken.value.accessToken = res.data.access_token;
            userToken.value.refreshToken = res.data.refresh_token;
            userId.value = res.data.id;
            isAuthenticated.value = true;

            sessionStorage.setItem("accessToken", res.data.access_token);
            sessionStorage.setItem("refreshToken", res.data.refresh_token);
            sessionStorage.setItem("userId", res.data.id);

            store.myData = await store.getSingleUserData(res.data.id);

            sessionStorage.setItem("username", store.myData.nickname);
            sessionStorage.setItem("auth", store.myData.role);

            await router.push({ name: 'ProjectList' });


            store.endThrottling();
        } catch (error) {
            console.error("Login error:", error);
            alert('등록되지 않은 아이디거나 비밀번호를 잘못 입력했습니다. 다시 한 번 입력해주세요.');
            store.endThrottling();
        }
    }

    function logout() {
        userToken.value.accessToken = "";
        userToken.value.refreshToken = "";
        userId.value = "";
        store.myData.nickname = "";
        store.myData.role = "";
        isAuthenticated.value = false;

        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("auth");
        router.push({ name: 'Login' });
    }

    async function refreshToken() {
        try {
            const rb = {
                refresh_token: userToken.value.refreshToken
            }
            const res = await api.post(ENDPOINTS.REFRESH_TOKEN, rb);
            userToken.value.accessToken = res.data.access_token;
            sessionStorage.setItem("accessToken", res.data.access_token);

            return true;
        } catch (error) {
            console.error("Token refresh error:", error);
            logout();
        }
    }

    return {
        userToken,
        isAuthenticated,
        userId,

        login,
        logout,
        refreshToken,
    };
});
