<script>
export default {
  name: "i-search"
}
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M9.42303 0C4.21883 0 0 4.21883 0 9.42303C0 14.6272 4.21883 18.8461 9.42303 18.8461C11.7006 18.8461 13.7895 18.038 15.4186 16.6929C15.4452 16.7288 15.4747 16.7631 15.5072 16.7956L22.4451 23.7335C22.8008 24.0892 23.3777 24.0892 23.7335 23.7335C24.0892 23.3777 24.0892 22.8008 23.7335 22.4451L16.7956 15.5072C16.7631 15.4747 16.7288 15.4452 16.6929 15.4186C18.038 13.7895 18.8461 11.7006 18.8461 9.42303C18.8461 4.21883 14.6272 0 9.42303 0ZM1.82208 9.42303C1.82208 5.22514 5.22514 1.82208 9.42303 1.82208C13.6209 1.82208 17.024 5.22514 17.024 9.42303C17.024 13.6209 13.6209 17.024 9.42303 17.024C5.22514 17.024 1.82208 13.6209 1.82208 9.42303Z"
          fill="currentColor"/>
  </svg>

</template>

<style scoped>

</style>
