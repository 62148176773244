<script>
export default {
  name: "i-trash"
}
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M9.71103 3.2501C10.3171 2.64399 11.1392 2.30348 11.9964 2.30348C12.8535 2.30348 13.6756 2.64399 14.2817 3.2501C14.8878 3.85613 15.2283 4.67809 15.2283 5.53516H8.76442C8.76449 4.67809 9.10499 3.85613 9.71103 3.2501ZM7.93475 7.15114L7.95643 7.15142L7.9781 7.15114H16.0147L16.0363 7.15142L16.058 7.15114H18.4569V20.887C18.4569 21.1012 18.3717 21.3068 18.2202 21.4583C18.0687 21.6098 17.8631 21.695 17.6489 21.695H6.33706C6.12285 21.695 5.91729 21.6098 5.76567 21.4582L5.76565 21.4582C5.61422 21.3068 5.52907 21.1013 5.52907 20.887V7.15114H7.93475ZM7.14844 5.53516C7.14851 4.24951 7.65926 3.01652 8.56836 2.10742C9.47753 1.19826 10.7106 0.6875 11.9964 0.6875C13.2821 0.6875 14.5152 1.19826 15.4244 2.10742C16.3335 3.01652 16.8442 4.24951 16.8443 5.53516H19.2649H21.6919C22.1382 5.53516 22.4999 5.89691 22.4999 6.34315C22.4999 6.78939 22.1382 7.15114 21.6919 7.15114H20.0729V20.887C20.0729 21.5299 19.8174 22.1465 19.3629 22.601C18.9084 23.0555 18.2918 23.3109 17.6489 23.3109H6.33706C5.69409 23.3109 5.07761 23.0554 4.62311 22.601L4.62309 22.601C4.16845 22.1464 3.91309 21.5298 3.91309 20.887V7.15114H2.30018C1.85394 7.15114 1.49219 6.78939 1.49219 6.34315C1.49219 5.89691 1.85394 5.53516 2.30018 5.53516H4.72108H7.14844ZM9.57264 10.3848C10.0189 10.3848 10.3806 10.7465 10.3806 11.1928V17.6592C10.3806 18.1054 10.0189 18.4672 9.57264 18.4672C9.1264 18.4672 8.76465 18.1054 8.76465 17.6592V11.1928C8.76465 10.7465 9.1264 10.3848 9.57264 10.3848ZM15.2244 11.1928C15.2244 10.7465 14.8626 10.3848 14.4164 10.3848C13.9701 10.3848 13.6084 10.7465 13.6084 11.1928V17.6592C13.6084 18.1054 13.9701 18.4672 14.4164 18.4672C14.8626 18.4672 15.2244 18.1054 15.2244 17.6592V11.1928Z"
          fill="currentColor"/>
  </svg>
</template>

<style scoped>

</style>
